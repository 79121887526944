import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerListA } from './routerList'
import store from '@/store'

Vue.use(VueRouter)

let routes = routerListA

const router = new VueRouter({
  mode: "hash",
  routes
})


//白名单
const whiteRouter = ['/login', '/forgetPassword', '/verificationCode', '/resetPassword', '/404'];   //indexOf方法，判断数组中是否存在指定的某个对象，如果不存在，则返回-1
let list = JSON.parse(localStorage.getItem('menuTree'));
//全局路由守卫   beforeEach:路由改变都会触发
router.beforeEach((to, from, next) => {

  //token令牌--处理权限
  if (localStorage.getItem('token')) {
    if (to.path === '/login') {
      localStorage.clear();
      sessionStorage.clear();
      next();
    } else {
      if (store.state.newRouterList.length || to.name != null) {
        //放行
        if (to.path === '/') {
          next({ path: list[0].path })
        } else {
          if (to.matched.length == 0) {
            next({ path: '/404' })
          }
          store.commit('setRouterPath', to)
          store.commit('setDefaultActive', to)
          next()
        }
      } else {
        // 将路由添加到 store 中，用来标记已添加动态路由
        store.commit('setNewRouter', { routerArr: JSON.parse(localStorage.getItem('menuTree'))})
        router.addRoutes(store.state.newRouterList)
        next({ ...to, replace: true })
      }
    }
  } else {
    if (whiteRouter.indexOf(to.path) !== -1) {  //当to.path == '/login' 的时候，存在，执行next(),跳到login页面,不触发beforeEach
      next(); //指向(进入)to钩子--进入下一个页面,to.path == '/login'
    } else {
      next('/login'); //发生路由指向变化，触发beforeEach ---> to.path == '/login'
    }
  }
})

export default router
