import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router/index'
import ElementUI from 'element-ui';
import watermark from './utils/util/watermark';
import Request from './utils/serve/request'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$watermark = watermark;
Vue.prototype.request = Request;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
