import { $ajax } from '../api'
import axios from 'axios';
// 产品模块

export default class Product {
    constructor() { }

    static productBath = {
        categoryList: '/api/product/category/list.action',
        categoryAdd: '/api/product/category/add.action',
        categoryEdit: '/api/product/category/edit.action',
        modelList: '/api/product/model/list.action',
        modelAdd: '/api/product/model/add.action',
        modelEdit: '/api/product/model/edit.action',
        manufacturerList: '/api/product/manufacturer/list.action',
        manufacturerAdd: '/api/product/manufacturer/add.action',
        manufacturerEdit: '/api/product/manufacturer/edit.action',
        productList: '/api/product/list.action',
        productAdd: '/api/product/add.action',
        productEdit: '/api/product/edit.action',
        productAll: '/api/product/all.action',
        dictionaryList: '/api/product/dictionary/list.action',
        dictionaryAdd: '/api/product/dictionary/add.action',
        dictionaryEdit: '/api/product/dictionary/edit.action',
        protocolList: '/api/product/protocol/list.action',
        protocolInfo: '/api/product/protocol/info.action',
        protocolAdd: '/api/product/protocol/add.action',
        protocolEdit: '/api/product/protocol/edit.action',
        listDictionary: '/api/product/protocol/listDictionary.action',
        moduleList: '/api/product/module/list.action',
        moduleAdd: '/api/product/module/add.action',
        moduleEdit: '/api/product/module/edit.action',
        searchCodec: '/api/product/codec/list.action',
        protocolAdjust: '/api/product/protocol/adjust.action',
    }

    //协议错误纠偏
    static protocolAdjust = (params) => {
        return $ajax.post(`${this.productBath.protocolAdjust}`,params).then(res => res.data)
    }

    //获取产品类型列表
    static categoryList = () => {
        return $ajax.post(`${this.productBath.categoryList}`).then(res => res.data)
    }

    //新增产品类型
    static categoryAdd = (params) => {
        return $ajax.post(`${this.productBath.categoryAdd}`,params).then(res => res.data)
    }

    //编辑产品类型
    static categoryEdit = (params) => {
        return $ajax.post(`${this.productBath.categoryEdit}`,params).then(res => res.data)
    }
    
    //获取产品型号列表
    static modelList = (params) => {
        return $ajax.post(`${this.productBath.modelList}`,params).then(res => res.data)
    }

    //新增产品型号
    static modelAdd = (params) => {
        return $ajax.post(`${this.productBath.modelAdd}`,params).then(res => res.data)
    }

    //编辑产品型号
    static modelEdit = (params) => {
        return $ajax.post(`${this.productBath.modelEdit}`,params).then(res => res.data)
    }

    //获取制造商列表
    static manufacturerList = () => {
        return $ajax.post(`${this.productBath.manufacturerList}`).then(res => res.data)
    }

    //新增制造商
    static manufacturerAdd = (params) => {
        return $ajax.post(`${this.productBath.manufacturerAdd}`,params).then(res => res.data)
    }

    //编辑制造商
    static manufacturerEdit = (params) => {
        return $ajax.post(`${this.productBath.manufacturerEdit}`,params).then(res => res.data)
    }

    //分页获取产品列表
    static productList = () => {
        return $ajax.post(`${this.productBath.productList}`).then(res => res.data)
    }

    //新增产品
    static productAdd = (params) => {
        return $ajax.post(`${this.productBath.productAdd}`,params).then(res => res.data)
    }

    //编辑产品
    static productEdit = (params) => {
        return $ajax.post(`${this.productBath.productEdit}`,params).then(res => res.data)
    }

     //获取全部产品列表
     static productAll = () => {
        return $ajax.post(`${this.productBath.productAll}`).then(res => res.data)
    }

    //获取字典列表
    static dictionaryList = (params) => {
        return $ajax.post(`${this.productBath.dictionaryList}`,params).then(res => res.data)
    }

    //新增数据字典
    static dictionaryAdd = (params) => {
        return $ajax.post(`${this.productBath.dictionaryAdd}`,params).then(res => res.data)
    }

    //编辑数据字典
    static dictionaryEdit = (params) => {
        return $ajax.post(`${this.productBath.dictionaryEdit}`,params).then(res => res.data)
    }

    //获取产品协议列表
    static protocolList = (params) => {
        return $ajax.post(`${this.productBath.protocolList}`,params).then(res => res.data)
    }

    //获取产品协议详情
    static protocolInfo = (params) => {
        return $ajax.post(`${this.productBath.protocolInfo}`,params).then(res => res.data)
    }

    //新增产品协议
    static protocolAdd = (params) => {
        return $ajax.post(`${this.productBath.protocolAdd}`,params).then(res => res.data)
    }

    //编辑产品协议
    static protocolEdit = (params) => {
        return $ajax.post(`${this.productBath.protocolEdit}`,params).then(res => res.data)
    }
 
    //获取协议字典
    static listDictionary = (params) => {
        return $ajax.post(`${this.productBath.listDictionary}`,params).then(res => res.data)
    }

    //获取模块列表
    static moduleList = (params) => {
        return $ajax.post(`${this.productBath.moduleList}`,params).then(res => res.data)
    }

    //新增模块
    static moduleAdd = (params) => {
        return $ajax.post(`${this.productBath.moduleAdd}`,params).then(res => res.data)
    }

    //编辑模块
    static moduleEdit = (params) => {
        return $ajax.post(`${this.productBath.moduleEdit}`,params).then(res => res.data)
    }

    //搜索协议
    static searchCodec = (params) => {
        return $ajax.post(`${this.productBath.searchCodec}`,params).then(res => res.data)
    }

}