import Layout from '@/views/index.vue'

export const routerListA = [
    //layout
    {
        path: '/',
        component: Layout,
        hidden: false,
        meta: {
            title: 'Layout'
        },
        name: 'Layout',
        children: [
           
        ]
    },
    //登录
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/login/index.vue"),
        meta: {
            title: '登录'
        },
        hidden: true
    },
    //忘记密码
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import("@/views/login/forgetPassword.vue"),
        meta: {
            title: '忘记密码'
        },
        hidden: true
    },
    //重置密码
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import("@/views/login/resetPassword.vue"),
        meta: {
            title: '重置密码'
        },
        hidden: true
    },
    //测试用
    {
        path: '/404',
        name: '404',
        component: () => import("@/views/404.vue"),
        meta: {
            title: '404'
        },
        hidden: true
    },
]
