let watermark = {}

let setWatermark = (str, top, left) => {
  let id = '1.23452384164.123412415'

  if (document.getElementById(id) !== null) {
    document.getElementById('dilu-table').removeChild(document.getElementById(id))
  }

  let can = document.createElement('canvas')
  can.width = 200
  can.height = 100

  let cans = can.getContext('2d')
  cans.rotate(-20 * Math.PI / 180)
  cans.font = '14px Vedana'
  cans.fillStyle = 'rgba(0, 0, 0, 0.50)'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  cans.fillText(str, can.width / 10, can.height)

  let div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = `${top}px`
  div.style.left = `${left}px`
  div.style.position = 'absolute'
  div.style.zIndex = '10000'
  div.style.opacity = '.19'
  div.style.width = document.documentElement.clientWidth * 0.9 + 'px'
  div.style.height = document.documentElement.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  document.getElementById('dilu-table').appendChild(div)
}

watermark.set = (str, top, left) => {
  setWatermark(str, top, left)
}

export default watermark