import { $ajax } from '../api'
import { $dxios } from '../api'

// 首页概览

export default class Home {

    constructor() { }

    static home = {
        staticSummary: '/api/static/summary.action',   //平台汇总数据
        staticGateway: '/api/static/gateway.action',   //通讯网关活跃数
        staticUser: '/api/static/user.action',         //APP用户活跃数
        staticApi: '/api/static/api.action',           //平台API访问数
        staticDevice: '/api/static/device.action',     //平台接入设备数
    }

    //平台汇总数据
    static staticSummary = (params) => {
        return $ajax.post(`${this.home.staticSummary}`, params).then(res => res.data);
    }

    //通讯网关活跃数
    static staticGateway = (params) => {
        return $ajax.post(`${this.home.staticGateway}`, params).then(res => res.data);
    }

    //APP用户活跃数
    static staticUser = (params) => {
        return $ajax.post(`${this.home.staticUser}`, params).then(res => res.data);
    }

    //平台API访问数
    static staticApi = (params) => {
        return $ajax.post(`${this.home.staticApi}`, params).then(res => res.data);
    }

    //平台接入设备数
    static staticDevice = (params) => {
        return $ajax.post(`${this.home.staticDevice}`, params).then(res => res.data);
    }
    
    
}