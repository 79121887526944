<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>


<script>
import { mapMutations, mapState } from "vuex";
import { updateThemeColor } from "./utils/util/theme";
export default {
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
        updateThemeColor(this.ORIGINAL_THEME);
      }, 1000
    );
  },
  computed: {
    ...mapState(["ORIGINAL_THEME"]),
  },
  methods: {
    ...mapMutations(["setTheme"]),
  },
};
</script>

<style>
@import "./assets/css/reset.css";
html,
body {
  width: 100%;
  height: 100%;
}
:root {
  
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;
  --white: #ffffff;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f6f9;
  overflow: hidden;
}

.app-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.from {
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.from .el-form-item{
  margin-right: 20px;
}

.search {
  margin-bottom: 20px;
}
</style>
