import { $ajax } from '../api'
import axios from 'axios';
// 公共配置模块

export default class Config {
    constructor() { }

    static configBath = {
        qiniuUploadToken: '/api/qiniu/qiniuUploadToken.action',//获取七牛云token等
        resetSelfPassword: '/api/system/resetSelfPassword.action',//修改密码
        uploadQiniu: 'https://upload.qiniup.com/', //上传七牛
    }

    /**
     * 获取七牛云token等
     */
    static qiniuUploadToken = () => {
        return $ajax.get(`${this.configBath.qiniuUploadToken}`).then(res => res.data)
    }

    /**
    * 修改密码
    */
    static resetSelfPassword = (params) => {
        return $ajax.post(`${this.configBath.resetSelfPassword}`, params).then(res => res.data)
    }

    /**
     * 上传七牛
     */
    static uploadQiniu = (params) => {
        return $ajax.post(`${this.configBath.uploadQiniu}`, params).then((res) => res.data)
    }
}