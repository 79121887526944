import { $ajax } from '../api'

// 系统模块

export default class Ststem {
    constructor() { }

    static systemBath = {
        //菜单模块
        listWithButtons: '/api/system/platformPermissions/menuManagement/listWithButtons.action', //查询所有菜单带按钮
        listAllWithButtons: '/api/system/platformPermissions/menuManagement/listAllWithButtons.action', //查询所有菜单带按钮
        menuManagementAdd: '/api/system/platformPermissions/menuManagement/add.action', //新增菜单
        menuManagementDelete: '/api/system/platformPermissions/menuManagement/delete.action', //删除菜单
        menuManagementEdit: '/api/system/platformPermissions/menuManagement/edit.action',//编辑菜单
        menuManagementAddButton: '/api/system/platformPermissions/menuManagement/addButton.action', //新增按钮
        menuManagementEditButton: '/api/system/platformPermissions/menuManagement/editButton.action', //编辑按钮
        menuManagementDeleteButton: '/api/system/platformPermissions/menuManagement/deleteButton.action', //删除按钮
        //角色模块
        roleManagementList: '/api/system/platformPermissions/roleManagement/list.action', //角色管理-列表（分页）
        roleManagementListAll: '/api/system/platformPermissions/roleManagement/listAll.action',//角色管理-查询所有角色   
        roleManagementAdd: '/api/system/platformPermissions/roleManagement/add.action', //新增角色
        roleManagementDelete: '/api/system/platformPermissions/roleManagement/delete.action', //角色管理删除角色
        roleManagementInfo: '/api/system/platformPermissions/roleManagement/info.action', //角色管理-根据id查询菜单按钮权限
        roleManagementEdit: '/api/system/platformPermissions/roleManagement/edit.action', //角色管理-编辑
        //部门模块
        departmentManagementListAll: '/api/system/platformPermissions/departmentManagement/listAll.action', //部门管理-查询所有部门
        departmentManagementInfo: '/api/system/platformPermissions/departmentManagement/info.action', // 部门管理-根据id查询
        departmentManagementDelete: '/api/system/platformPermissions/departmentManagement/delete.action',// 部门管理-删除部门
        departmentManagementAdd: '/api/system/platformPermissions/departmentManagement/add.action', //部门管理-新增部门
        //账号模块
        accountManagementList: '/api/system/platformPermissions/accountManagement/list.action', // 账号管理-列表（分页）
        accountManagementAdd: '/api/system/platformPermissions/accountManagement/add.action', //账号管理-新增
        accountManagementEdit: '/api/system/platformPermissions/accountManagement/edit.action', //账号管理-编辑
        accountManagementDelete: '/api/system/platformPermissions/accountManagement/delete.action', // 账号管理-删除
        accountManagementResetPassword: '/api/system/platformPermissions/accountManagement/resetPassword.action',// 账号管理-重置密码
        accountManagementShare: '/api/system/platformPermissions/accountManagement/share.action', //账号管理-一键分享
        accountManagementInfo: '/api/system/platformPermissions/accountManagement/info.action', // 账号管理，根据id查详情
        //日志管理
        apiList: '/api/system/operationLog/apiList.action', //日志维护列表
        apiAdd: '/api/system/operationLog/apiAdd.action', //日志添加
        apiEdit: '/api/system/operationLog/apiEdit.action', //日志编辑
        apiDelete: '/api/system/operationLog/apiDelete.action', //日志删除
        admList: '/api/system/operationLog/admList.action', //管理后台操作日志记录
        toolList: '/api/system/operationLog/toolList.action', //运维端日志记录
        appList: '/api/system/operationLog/appList.action', //APP用户操作日志记录
        queryAppRequestUrl: '/api/system/operationLog/queryAppRequestUrl.action', //根据条件查询app接口
    }

    //菜单模块

    /**
     * 查询所有菜单带按钮
     */
    static listWithButtons = (params) => {
        return $ajax.post(`${this.systemBath.listWithButtons}`,params).then(res => res.data)
    }

    /**
     * 查询所有菜单带按钮
     */
    static listAllWithButtons = () => {
        return $ajax.get(`${this.systemBath.listAllWithButtons}`).then(res => res.data)
    }

    /**
    * 新增菜单
    */
    static menuManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementAdd}`, params).then(res => res.data)
    }

    /**
     * 菜单管理删除菜单
     */
    static menuManagementDelete = (params) => {
        return $ajax.delete(`${this.systemBath.menuManagementDelete}${params}`).then(res => res.data)
    }


    /**
     * 编辑菜单
     */
    static menuManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementEdit}`, params).then(res => res.data)
    }

    /**
     * 新增按钮
     */
    static menuManagementAddButton = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementAddButton}`, params).then(res => res.data)
    }


    /**
     * 编辑按钮
     */
    static menuManagementEditButton = (params) => {
        return $ajax.post(`${this.systemBath.menuManagementEditButton}`, params).then(res => res.data)
    }

    /**
     * 删除按钮
     */
    static menuManagementDeleteButton = (params) => {
        return $ajax.delete(`${this.systemBath.menuManagementDeleteButton}${params}`).then(res => res.data)
    }




    //角色模块

    /**
     * 角色管理-列表（分页）
     */
    static roleManagementList = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementList}`, params).then(res => res.data)
    }


    /**
     * 角色管理-查询所有角色
     */
    static roleManagementListAll = () => {
        return $ajax.get(`${this.systemBath.roleManagementListAll}`).then(res => res.data)
    }


    /**
     * 新增角色
     */
    static roleManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementAdd}`, params).then(res => res.data)
    }


    /**
     * 角色管理删除角色
     */
    static roleManagementDelete = (params) => {
        return $ajax.delete(`${this.systemBath.roleManagementDelete}${params}`).then(res => res.data)
    }

    /**
     * 角色管理-根据id查询菜单按钮权限
     */
    static roleManagementInfo = (params) => {
        return $ajax.get(`${this.systemBath.roleManagementInfo}${params}`).then(res => res.data)
    }

    /**
     * 角色管理-编辑
     */
    static roleManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.roleManagementEdit}`, params).then(res => res.data)
    }




    //部门模块

    /**
     * 部门管理-查询所有部门
     */
    static departmentManagementListAll = () => {
        return $ajax.get(`${this.systemBath.departmentManagementListAll}`).then(res => res.data)
    }

    /**
     * 部门管理-根据id查询
     */
    static departmentManagementInfo = (params) => {
        return $ajax.get(`${this.systemBath.departmentManagementInfo}${params}`).then(res => res.data)
    }

    /**
     * 部门管理-删除部门
     */
    static departmentManagementDelete = (params) => {
        return $ajax.post(`${this.systemBath.departmentManagementDelete}`, params).then(res => res.data)
    }

    /**
     * 部门管理-新增部门
     */
    static departmentManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.departmentManagementAdd}`, params).then(res => res.data)
    }


    //账号模块

    /**
     * 账号管理-列表（分页）
     */
    static accountManagementList = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementList}`, params).then(res => res.data)
    }


    /**
     * 账号管理-新增
     */
    static accountManagementAdd = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementAdd}`, params).then(res => res.data)
    }

    /**
     * 账号管理-编辑
     */
    static accountManagementEdit = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementEdit}`, params).then(res => res.data)
    }


    /**
     * 账号管理-删除
     */
    static accountManagementDelete = (params) => {
        return $ajax.delete(`${this.systemBath.accountManagementDelete}?id=${params}`).then(res => res.data)
    }


    /**
     * 账号管理-重置密码
     */
    static accountManagementResetPassword = (params) => {
        return $ajax.post(`${this.systemBath.accountManagementResetPassword}`, params).then(res => res.data)
    }


    /**
     * 账号管理-一键分享
     */
    static accountManagementShare = (params) => {
        return $ajax.get(`${this.systemBath.accountManagementShare}?id=${params}`).then(res => res.data)
    }

    /**
     * 账号管理，根据id查详情
     */
    static accountManagementInfo = (params) => {
        return $ajax.get(`${this.systemBath.accountManagementInfo}?id=${params}`).then(res => res.data)
    }



    // app协议模块
    /**
     * app协议-列表（分页）
     */
    static appAgreementList = (params) => {
        return $ajax.post(`${this.systemBath.appAgreementList}`, params).then(res => res.data)
    }

    /**
     * app协议-查出所有协议的应用路径
     */
    static appAgreementGetFunctionCode = (params) => {
        return $ajax.post(`${this.systemBath.appAgreementGetFunctionCode}`, params).then(res => res.data)
    }

    /**
     * app协议-新增
     */
    static appAgreementAdd = (params) => {
        return $ajax.post(`${this.systemBath.appAgreementAdd}`, params).then(res => res.data)
    }

    /**
     * app协议-删除
     */
    static appAgreementDelete = (params) => {
        return $ajax.post(`${this.systemBath.appAgreementDelete}`, params).then(res => res.data)
    }


    /**
     * app协议-编辑
     */
    static appAgreementEdit = (params) => {
        return $ajax.post(`${this.systemBath.appAgreementEdit}`, params).then(res => res.data)
    }




    //APP版本模块
    /**
     * APP版本-分页列表查询
     */
    static versionManagementList = (params) => {
        return $ajax.post(`${this.systemBath.versionManagementList}`, params).then(res => res.data)
    }


    /**
     * APP版本-批量删除
     */
    static versionManagementDelete = (params) => {
        return $ajax.post(`${this.systemBath.versionManagementDelete}`, params).then(res => res.data)
    }


    /**
     * APP版本-新增
     */
    static versionManagementSave = (params) => {
        return $ajax.post(`${this.systemBath.versionManagementSave}`, params).then(res => res.data)
    }


    /**
     * APP版本-修改
     */
    static versionManagementUpdate = (params) => {
        return $ajax.post(`${this.systemBath.versionManagementUpdate}`, params).then(res => res.data)
    }


    /**
     * 查询所有待审核app版本
     */
    static versionReviewReviewList = (params) => {
        return $ajax.post(`${this.systemBath.versionReviewReviewList}`, params).then(res => res.data)
    }


    /**
     *  审核
     */
    static versionReviewReview = (params) => {
        return $ajax.post(`${this.systemBath.versionReviewReview}`, params).then(res => res.data)
    }



    // 日志
    /**
     * 管理后台操作日志记录
     */
    static admList = (params) => {
        return $ajax.post(`${this.systemBath.admList}`, params).then(res => res.data)
    }
    /**
     * 运维端操作日志记录
     */
    static toolList = (params) => {
        return $ajax.post(`${this.systemBath.toolList}`, params).then(res => res.data)
    }

    // 日志
    /**
     * App操作日志记录
     */
    static appList = (params) => {
        return $ajax.post(`${this.systemBath.appList}`, params).then(res => res.data)
    }

    /**
     * 日志维护列表
     */
    static apiList = (params) => {
        return $ajax.post(`${this.systemBath.apiList}`, params).then(res => res.data)
    }

    /**
     * 管理后台操作日志记录
     */
    static admList = (params) => {
        return $ajax.post(`${this.systemBath.admList}`, params).then(res => res.data)
    }

    /**
     * 日志添加
     */
    static apiAdd = (params) => {
        return $ajax.post(`${this.systemBath.apiAdd}`, params).then(res => res.data)
    }

    /**
     * 日志编辑
     */
    static apiEdit = (params) => {
        return $ajax.post(`${this.systemBath.apiEdit}`, params).then(res => res.data)
    }

    /**
     * 日志删除
     */
    static apiDelete = (params) => {
        return $ajax.post(`${this.systemBath.apiDelete}`, params).then(res => res.data)
    }
    
    /**
    * 快捷搜索-根据条件查询app接口
    */
    static queryAppRequestUrl = (params) => {
        return $ajax.get(`${this.systemBath.queryAppRequestUrl}${params}`).then(res => res.data)
    }
}