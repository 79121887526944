import { $ajax } from '../api'
import { $dxios } from '../api'

// 租户管理

export default class Tenant {

    constructor() { }

    static tenant = {
        queryList: '/api/tenant/queryList.action',           //查询租户
        tenantAll: '/api/tenant/all.action',                 //获取全部租户
        tenantAdd: '/api/tenant/tenantAdd.action',           //新增租户
        tenantEdit: '/api/tenant/tenantEdit.action',         //修改租户
        getProvince: '/api/tenant/getProvince.action',       //下属省份
        getCity: '/api/tenant/getCity.action',               //下属城市
        getArea: '/api/tenant/getArea.action',               //下属区县
        tenantMenuAdd: '/api/tenant/menuAdd.action',                //新增菜单
        tenantMenuEdit: '/api/tenant/menuEdit.action',              //编辑菜单
        tenantMenuDelete: '/api/tenant/menuDelete.action',          //删除菜单
        tenantButtonAdd: '/api/tenant/addButton.action',            //新增按钮
        tenantButtonEdit: '/api/tenant/editButton.action',          //编辑按钮
        tenantButtonDelete: '/api/tenant/deleteButton.action',      //删除按钮
        syncTenantMenu: '/api/tenant/syncTenantMenu.action',        //同步租户菜单
        tenantAccountShare: '/api/tenant/tenantAccountShare.action',//分享租户账号
        listTenantMenus: '/api/tenant/listTenantMenus.action',      //获取租户菜单
        saveTenantMenus: '/api/tenant/saveTenantMenus.action',      //保存租户菜单
        resetTenantPassword: '/api/tenant/resetTenantPassword.action',       //账号管理-重置密码
        listTenantMenuButtons: '/api/tenant/listTenantMenuButtons.action',   //查询所有菜单带按钮
        saveTenantProduct: '/api/tenant/saveTenantProduct.action',           //保存租户产品
        listTenantProduct: '/api/tenant/listTenantProduct.action',           //获取租户产品
        syncData: '/api/tenant/syncData.action',                             //运营平台与租户之间数据同步
    }

    /**
     * 查询所有菜单带按钮
     */
    static listTenantMenuButtons = () => {
        return $ajax.get(`${this.tenant.listTenantMenuButtons}`).then(res => res.data)
    }

    /**
    * 新增菜单
    */
    static tenantMenuAdd = (params) => {
        return $ajax.post(`${this.tenant.tenantMenuAdd}`, params).then(res => res.data)
    }

    /**
     * 编辑菜单
     */
    static tenantMenuEdit = (params) => {
        return $ajax.post(`${this.tenant.tenantMenuEdit}`, params).then(res => res.data)
    }

    /**
     * 菜单管理删除菜单
     */
    static tenantMenuDelete = (params) => {
        return $ajax.delete(`${this.tenant.tenantMenuDelete}${params}`).then(res => res.data)
    }


    /**
     * 新增按钮
     */
    static tenantButtonAdd = (params) => {
        return $ajax.post(`${this.tenant.tenantButtonAdd}`, params).then(res => res.data)
    }


    /**
     * 编辑按钮
     */
    static tenantButtonEdit = (params) => {
        return $ajax.post(`${this.tenant.tenantButtonEdit}`, params).then(res => res.data)
    }

    /**
     * 删除按钮
     */
    static tenantButtonDelete = (params) => {
        return $ajax.delete(`${this.tenant.tenantButtonDelete}${params}`).then(res => res.data)
    }

    
    //获取全部租户
    static tenantAll = (params) => {
        return $ajax.post(`${this.tenant.tenantAll}`, params).then(res => res.data);
    }

    //查询租户
    static queryList = (params) => {
        return $ajax.post(`${this.tenant.queryList}`, params).then(res => res.data);
    }

    //新增租户
    static tenantAdd = (params) => {
        return $ajax.post(`${this.tenant.tenantAdd}`, params).then(res => res.data);
    }

    //修改租户
    static tenantEdit = (params) => {
        return $ajax.post(`${this.tenant.tenantEdit}`, params).then(res => res.data);
    }

    //下属省份
    static getProvince = () => {
        return $ajax.post(`${this.tenant.getProvince}`).then(res => res.data);
    }

    //下属城市
    static getCity = (params) => {
        return $ajax.post(`${this.tenant.getCity}`, params).then(res => res.data);
    }

    //下属区县
    static getArea = (params) => {
        return $ajax.post(`${this.tenant.getArea}`, params).then(res => res.data);
    }
    
    //同步租户菜单
    static syncTenantMenu = () => {
        return $ajax.post(`${this.tenant.syncTenantMenu}`).then(res => res.data);
    }
    
    //分享租户账号
    static tenantAccountShare = (params) => {
        return $ajax.post(`${this.tenant.tenantAccountShare}`, params).then(res => res.data);
    }

    //分享租户账号
    static listTenantMenus = (params) => {
        return $ajax.post(`${this.tenant.listTenantMenus}`, params).then(res => res.data);
    }

    //保存租户菜单
    static saveTenantMenus = (params) => {
        return $ajax.post(`${this.tenant.saveTenantMenus}`, params).then(res => res.data);
    }

    //重置管理员密码
    static resetTenantPassword = (params) => {
        return $ajax.post(`${this.tenant.resetTenantPassword}`, params).then(res => res.data);
    }
    
    //保存租户产品
    static saveTenantProduct = (params) => {
        return $ajax.post(`${this.tenant.saveTenantProduct}`, params).then(res => res.data);
    }

    //获取租户产品
    static listTenantProduct = (params) => {
        return $ajax.post(`${this.tenant.listTenantProduct}`, params).then(res => res.data);
    }

    //运营平台与租户之间数据同步
    static syncData = () => {
        return $ajax.post(`${this.tenant.syncData}`).then(res => res.data);
    }

}