<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <div class="header-left">
        <div class="header-logo">
            <img style="height:35px;margin-bottom:10px;" src="../assets/images/logo.png"/>
        </div>
        <div class="header-title">
          <div class="header-title">运营管理平台</div>
        </div>
      </div>
      <div class="header-right">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-demo"
          mode="horizontal"
          background-color="rgba(255,255,255,.5)"
          text-color="#000"
          active-text-color="#1F5BCC"
          router>
          <template v-for="(item, index) in list">
            <el-menu-item v-if="!item.hidden" :key="index" :index="'/' + item.path" style="font-size:16px;">{{ item.meta.title }}</el-menu-item>
          </template>
        </el-menu>
        <div class="user">
          <el-dropdown @command="userOperation">
            <span class="el-dropdown-link">
              <el-avatar v-if="userInfo.imagePath" :size="30" :src="userInfo.imagePath"/>
              <el-avatar v-else :size="30" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
              <span class="name">{{ userInfo.username }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">修改密码</el-dropdown-item>
              <el-dropdown-item command="2">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <!-- 内容 -->
    <el-main style="padding:0px;">
      <router-view class="app-view"></router-view>
    </el-main>
  </el-container>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
      dialogVisible: false,
      userInfo: {},
    };
  },
  mounted() {
    this.setMenuLang();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  components: {
  },
  computed: {
    ...mapState([
      "defaultActive",
      "newRouterList",
    ]),
  },
  methods: {
    setMenuLang() {
      this.list = this.newRouterList[0].children;
    },
    // 关闭修改密码弹窗
    dialogClose() {
      this.dialogVisible = false;
    },
    //用户操作
    userOperation(e) {
      if (e == 2) {
        this.$confirm("你确定要退出吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.request.logout().then(() => {
            this.$message({
              type: "success",
              message: "退出成功!",
            });
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {});
      }
      if (e == 1) {
        this.dialogVisible = true;
      }
    },
  }
}
</script>


<style scoped>
.el-container {
  overflow: auto;
}
.el-header {
  color: #000;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  margin-bottom: 1px;
  z-index: 1;
  height: 80px;
  box-shadow: 0px 3px 6px rgba(210, 222, 244, 0.69);
}
.header-left {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 20px;
  margin: 0 20px;
}

.el-header .header-right {
  display: flex;
  align-items: center;
}

.el-header .header-right .user {
  margin-left: 40px;
  cursor: pointer;
}

.el-header .header-right .user .el-dropdown-link {
  display: flex;
  align-items: center;
}
.el-header .header-right .user .el-dropdown-link .name {
  margin-left: 10px;
  font-size: 16px;
  color: #1f5bcc;
}

.view {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  /* box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
  border-radius: 10px; */
}
.layout-row {
  flex: 1;
  overflow: hidden;
}
.layout-col {
  height: 100%;
  overflow: hidden;
}
.layout-right {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-region {
  background: rgba(210, 222, 244, 0.35);
  border-radius: 15px;
  padding: 4px 8px;
  box-sizing: border-box;
}
</style>