// 所有请求的封装
// bath对象：所有接口
// i8n：国际化
// $ajax:所有需要token的接口
// loginAxios:登录、获取平台方信息、等不需要token的接口
import axios from 'axios';
import ElementUI from 'element-ui';

//普通操作请求 (需要token)
export const $ajax = axios.create({
    // timeout: 15000
});

//登录操作请求 (不需要token)
export const loginAxios = axios.create({
    timeout: 15000
});

//文件下载 (不需要检查返回值)
export const $dxios = axios.create({
    timeout: 15000
});

//给请求添加一个请求拦截器
$ajax.interceptors.request.use(function (config) {
    config.headers.common['Accept-Language'] = 'zh-CN';
    if (localStorage.getItem('token')) {
        config.headers.common['Authorization'] = localStorage.getItem('token');
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
$ajax.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.data.code != 0) {
        ElementUI.Message.error(response.data.msg);
        if (response.data.code == 999) {
            setTimeout(() => {
                ElementUI.Message.closeAll();
                window.location.href = window.location.origin + '/#/login';
                sessionStorage.clear();
                localStorage.clear();
            }, 1000);
        }
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    ElementUI.Message.error(error);
    return Promise.reject(error);
});



//给请求添加一个请求拦截器
loginAxios.interceptors.request.use(function (config) {
    config.headers.common['Accept-Language'] = 'zh-CN';
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
loginAxios.interceptors.response.use(function (response) {
    if (response.status == 500 || response.status == 503) {
        ElementUI.Message.error('服务器错误');
        return response;
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    ElementUI.Message.error(error);
    return Promise.reject(error);
});


//导出
export function exportMethod(url, name, suffix) {
    return axios({
        method: 'get',
        url: url,
        headers: {
            Authorization: localStorage.getItem('token'),
        },
        responseType: 'blob'
    }).then(res => {
        if (!res) {
            ElementUI.Message.error('导出失败');
            return
        }
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `${name}.${suffix ? suffix : 'xls'}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }).catch(() => {
        ElementUI.Message.error('服务器错误');
    })
}

//导出二维码
export function exportQrcodeMethod(url, name) {
    return axios({
        method: 'get',
        url: url,
        responseType: 'blob'
    }).then(res => {
        if (!res) {
            ElementUI.Message.error('下载失败');
            return
        }
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `${name}.png`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }).catch(() => {
        ElementUI.Message.error('服务器错误');
    })
}
