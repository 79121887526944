import Vue from 'vue'
import Vuex from 'vuex'
import { routerListA } from "../router/routerList";
import  router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    indexActive: [],
    routerPath: {},
    defaultActive: null,
    newRouterList: [],
  },
  mutations: {
    setDefaultActive(state, e) {
      state.defaultActive = "/" + e.path.split("/")[1];
    },
    //设置当前页面路由
    setRouterPath(state, e) {
      setTimeout(() => {
        if (state.newRouterList.length < 1) {
          return
        }
        let arr = state.newRouterList[0].children.filter((item) => {
          return item.path == e.path.split("/")[1];
        });
        if (arr.length < 1) {
          return
        }
        state.routerPath = arr[0];
        let paths = e.path.split("/");
        paths.splice(0, 1);
        if (paths.length < 2) {
          for (let i in state.routerPath.children) {
            if (state.routerPath.children[i].alias != undefined) {
              for (let j in state.routerPath.children[i].children) {
                if (state.routerPath.children[i].children[j].alias != undefined) {
                  state.indexActive = [i, j];
                }
              }
            }
          }
        } else {
          for (let i in state.routerPath.children) {
            if (state.routerPath.children[i].path == paths[1]) {
              for (let j in state.routerPath.children[i].children) {
                if (state.routerPath.children[i].children[j].path == paths[2]) {
                  state.indexActive = [i, j];
                }
              }
            }
          }
        }
      }, 100);
    },
    //清空路由
    clearNewRouter(state) {
      state.newRouterList = [];
    },
    //设置新路由表
    setNewRouter(state, e) {
      state.newRouterList = [];
      let list = e.routerArr;
      list[0].alias = '/'
      for (let i in list) {
        list[i].component = resolve => require(['@/views/' + list[i].path + '/index.vue'], resolve);
        if (list[i].children && list[i].children.length > 0) {
          list[i].children[0].alias = '';
          for (let j in list[i].children) {
            list[i].children[j].component = resolve => require(['@/views/' + list[i].path + '/' + list[i].children[j].path + '/index.vue'], resolve);
            if (list[i].children[j].children && list[i].children[j].children.length > 0) {
              list[i].children[j].children[0].alias = '';
              for (let k in list[i].children[j].children) {
                delete list[i].children[j].children[k].children;
                list[i].children[j].children[k].component = resolve => require(['@/views/' + list[i].path + '/' + list[i].children[j].path + '/' + list[i].children[j].children[k].path + '.vue'], resolve);
                if (list[i].children[j].children[k].name=="listdtu") {
                  list[i].children[j].children[k].meta['keepAlive']=true; 
                  console.log(list[i].children[j].children[k]);
                }
              }
            }
          }
        }
      }
      let arr1 = routerListA;
      arr1[0].children = list;
      router.addRoutes(arr1);
      state.newRouterList = arr1;
    }
  },
  actions: {
  },
  modules: {
  }
})
